// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/ehr-home/closeButton.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__item--V4Ium {\n  margin-bottom: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.s-module__item--V4Ium .s-module__slot--hXVn8 {\n  display: flex;\n  align-items: center;\n}\n.s-module__item--V4Ium .s-module__slot--hXVn8 .s-module__timeSelect--weL50 {\n  width: 150px;\n}\n.s-module__item--V4Ium .s-module__slot--hXVn8 .s-module__connect--kHmac {\n  margin: 0 18px;\n  font-size: 16px;\n}\n.s-module__item--V4Ium .s-module__delete--xLOt1 {\n  margin-left: 24px;\n  width: 24px;\n  height: 24px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: contain;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AppointmentTime/components/TimeSlot/s.module.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AALA;EAOQ,aAAA;EACA,mBAAA;AACR;AATA;EAWY,YAAA;AACZ;AAZA;EAeY,cAAA;EACA,eAAA;AAAZ;AAhBA;EAsBQ,iBAAA;EACA,WAAA;EACA,YAAA;EACA,oEAAA;EACA,wBAAA;EACA,eAAA;AAHR","sourcesContent":[".item {\n    margin-bottom: 12px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    .slot {\n        display: flex;\n        align-items: center;\n\n        .timeSelect {\n            width: 150px;\n        }\n\n        .connect {\n            margin: 0 18px;\n            font-size: 16px;\n        }\n\n    }\n\n    .delete {\n        margin-left: 24px;\n        width: 24px;\n        height: 24px;\n        background: url('assets/ehr-home/closeButton.svg') no-repeat center;\n        background-size: contain;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "s-module__item--V4Ium",
	"slot": "s-module__slot--hXVn8",
	"timeSelect": "s-module__timeSelect--weL50",
	"connect": "s-module__connect--kHmac",
	"delete": "s-module__delete--xLOt1"
};
export default ___CSS_LOADER_EXPORT___;
