// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__avaRecommand--o0kTC {\n  width: 153px;\n  margin-bottom: 4px;\n  display: inline;\n  border-radius: 10px;\n  padding: 2px 10px;\n  font-family: Inter;\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 16px;\n  color: var(--blue-800);\n  background-color: var(--blue-100);\n}\n.s-module__avaRecommand--o0kTC .s-module__avaLogo--mSxRQ {\n  display: inline;\n  font-family: Inter;\n  font-size: 12px;\n  font-weight: 700;\n  line-height: 16px;\n  text-align: center;\n  background: linear-gradient(270deg, #02FF74 -10.14%, #0BCA88 12.21%, #000AF0 109.45%);\n  background-clip: text;\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EHRAvaRecommandLabel/s.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,iCAAA;AACJ;AAZA;EAcQ,eAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,qFAAA;EACA,qBAAA;EACA,6BAAA;EACA,oCAAA;AACR","sourcesContent":[".avaRecommand {\n    width: 153px;\n    margin-bottom: 4px;\n    display: inline;\n    border-radius: 10px;\n    padding: 2px 10px;\n    font-family: Inter;\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 16px;\n    color: var(--blue-800);\n    background-color: var(--blue-100);\n\n    .avaLogo {\n        display: inline;\n        font-family: Inter;\n        font-size: 12px;\n        font-weight: 700;\n        line-height: 16px;\n        text-align: center;\n        background: linear-gradient(270deg, #02FF74 -10.14%, #0BCA88 12.21%, #000AF0 109.45%);\n        background-clip: text;\n        -webkit-background-clip: text;\n        -webkit-text-fill-color: transparent;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avaRecommand": "s-module__avaRecommand--o0kTC",
	"avaLogo": "s-module__avaLogo--mSxRQ"
};
export default ___CSS_LOADER_EXPORT___;
