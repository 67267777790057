// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--KFW1G {\n  display: -webkit-box;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  -webkit-box-orient: vertical;\n  padding-right: 48px;\n}\n.s-module__wrap--KFW1G .s-module__more--N67k9 {\n  display: none;\n}\n.s-module__wrapPreLine--FcCTK {\n  white-space: pre-line;\n}\n.s-module__wrapElli--DRmvf {\n  position: relative;\n}\n.s-module__wrapElli--DRmvf .s-module__more--N67k9 {\n  display: block;\n  color: #00816B;\n  font-weight: bold;\n  position: absolute;\n  right: 8px;\n  bottom: 0;\n  cursor: pointer;\n}\n.s-module__wrapAll--bwBlV {\n  display: block;\n}\n.s-module__wrapAll--bwBlV .s-module__more--N67k9 {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AjustText/s.module.less"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,gBAAA;EACA,uBAAA;EACA,4BAAA;EACA,mBAAA;AACJ;AANA;EAQQ,aAAA;AACR;AAGA;EACI,qBAAA;AADJ;AAIA;EACI,kBAAA;AAFJ;AACA;EAIQ,cAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;AAFR;AAMA;EACI,cAAA;AAJJ;AAGA;EAIQ,aAAA;AAJR","sourcesContent":[".wrap {\n    display: -webkit-box;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    -webkit-box-orient: vertical;\n    padding-right: 48px;\n\n    .more {\n        display: none;\n    }\n}\n\n.wrapPreLine {\n    white-space: pre-line;\n}\n\n.wrapElli {\n    position: relative;\n\n    .more {\n        display: block;\n        color: #00816B;\n        font-weight: bold;\n        position: absolute;\n        right: 8px;\n        bottom: 0;\n        cursor: pointer;\n    }\n}\n\n.wrapAll {\n    display: block;\n\n    .more {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--KFW1G",
	"more": "s-module__more--N67k9",
	"wrapPreLine": "s-module__wrapPreLine--FcCTK",
	"wrapElli": "s-module__wrapElli--DRmvf",
	"wrapAll": "s-module__wrapAll--bwBlV"
};
export default ___CSS_LOADER_EXPORT___;
