// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--E6AyZ {\n  display: flex;\n  padding: 36px;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n.s-module__wrap--E6AyZ .s-module__title--H7WGZ {\n  color: var(--Primary-Black, #15191E);\n  text-align: center;\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 30px;\n}\n.s-module__wrap--E6AyZ .s-module__des--aK5ym {\n  color: var(--White-gray, #6B7280);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 30px;\n  margin-bottom: 16px;\n  margin-top: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EmptyHolder/PatientTableEmpty/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACF;AANA;EAQI,oCAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAbA;EAgBI,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;AAAJ","sourcesContent":[".wrap {\n  display: flex;\n  padding: 36px;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n  .title {\n    color: var(--Primary-Black, #15191E);\n    text-align: center;\n    font-size: 20px;\n    font-weight: 500;\n    line-height: 30px;\n  }\n\n  .des {\n    color: var(--White-gray, #6B7280);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 30px;\n    margin-bottom: 16px;\n    margin-top: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--E6AyZ",
	"title": "s-module__title--H7WGZ",
	"des": "s-module__des--aK5ym"
};
export default ___CSS_LOADER_EXPORT___;
