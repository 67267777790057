// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__status--_PRTM {\n  margin-bottom: 16px;\n  color: var(--gray-700);\n  font-family: Inter;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  /* 142.857% */\n}\n.s-module__status--_PRTM.s-module__valid--b38Xx {\n  color: var(--main-blue);\n}\n.s-module__status--_PRTM.s-module__invalid--HyGQ4 {\n  color: var(--red-500);\n}\n.s-module__status--_PRTM::before {\n  content: '•';\n  margin-right: 9px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DisputeStatusTag/s.module.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACF,aAAa;AACf;AAAI;EACI,uBAAA;AAER;AACI;EACI,qBAAA;AACR;AACI;EACI,YAAA;EACA,iBAAA;AACR","sourcesContent":[".status {\n    margin-bottom: 16px;\n    color: var(--gray-700);\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px; /* 142.857% */\n\n    &.valid {\n        color: var(--main-blue)\n    }\n\n    &.invalid {\n        color: var(--red-500)\n    }\n    &::before {\n        content: '•';\n        margin-right: 9px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "s-module__status--_PRTM",
	"valid": "s-module__valid--b38Xx",
	"invalid": "s-module__invalid--HyGQ4"
};
export default ___CSS_LOADER_EXPORT___;
