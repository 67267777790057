import React, { useCallback, useEffect, useMemo, useState } from "react";
import s from "./s.module.less";
import { Input, Popover } from "antd";
import Slider from "../Slider";
import { EServiceType, ServerMeta, TKlarityStateTPrice } from "types/common";
import { getKlarityPriceLevel } from 'utils/service';

interface IProps {
    consultationOverPrice: number;
    isConsultation: boolean;
    stateContentDurationMap: Record<string, number>,
    stateContentMyEaringMap: Record<string, number>,
    item: ServerMeta;
    serviceType: EServiceType,
    allStateConfigTypePriceData?: Record<string, TKlarityStateTPrice>,
    setStateContentMyEaringMap: (value: Record<string, number>) => void;
    onChange?: (value: number) => void;
    value?: number;
}

const EarningEditor = ({
    consultationOverPrice,
    isConsultation,
    item,
    allStateConfigTypePriceData,
    stateContentDurationMap,
    stateContentMyEaringMap,
    serviceType,
    setStateContentMyEaringMap,
    value,
    onChange,
}: IProps) => {
    const [priceLevel, setPriceLevel] = useState(0);
    const [bgColor, setBgColor] = useState('');
    const handleChange = useCallback((v: number, pl: number, bc: string) => {
        setPriceLevel(pl);
        setBgColor(bc);

        onChange?.(v);
    }, [onChange]);
    const [forceUpdate, setForceUpdate] = useState({});
    const popContent = useMemo(() => {
        return (
            <div className={s.popContentWrap}>
                <Slider
                    forceUpdate={forceUpdate}
                    consultationOverPrice={consultationOverPrice}
                    isConsultation={isConsultation}
                    item={item}
                    serviceType={serviceType}
                    stateContentDurationMap={stateContentDurationMap}
                    stateContentMyEaringMap={stateContentMyEaringMap}
                    setStateContentMyEaringMap={setStateContentMyEaringMap}
                    allStateConfigTypePriceData={allStateConfigTypePriceData}
                    onChange={handleChange}
                />
                <div className={s.tags}>
                    <div className={s.tag} style={{backgroundColor: `${priceLevel === 1 ? bgColor : ''}`}}>Very competitive</div>
                    <div className={s.tag} style={{backgroundColor: `${priceLevel === 3 ? bgColor : ''}`}}>Average</div>
                    <div className={s.tag} style={{backgroundColor: `${priceLevel === 5 ? bgColor : ''}`}}>Not competitive</div>
                </div>
            </div>
        )
    }, [forceUpdate, consultationOverPrice, isConsultation, item, serviceType, stateContentMyEaringMap, setStateContentMyEaringMap, allStateConfigTypePriceData, stateContentDurationMap, priceLevel, bgColor]);

    return (
        <div className={s.wrap}>
            {/* <Slider
                    consultationOverPrice={consultationOverPrice}
                    isConsultation={isConsultation}
                    item={item}
                    serviceType={serviceType}
                    stateContentDurationMap={stateContentDurationMap}
                    stateContentMyEaringMap={stateContentMyEaringMap}
                    setStateContentMyEaringMap={setStateContentMyEaringMap}
                    allStateConfigTypePriceData={allStateConfigTypePriceData}
                /> */}
            <Popover
                content={popContent}
                trigger="click"
            >
                <Input onFocus={() => {
                    setTimeout(() => {
                        setForceUpdate({})
                    }, 10);
                }} value={stateContentMyEaringMap[item.content]} />
            </Popover>
        </div>
    )
}

export default EarningEditor;
